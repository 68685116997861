import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import type { Item } from '@tag/graphql';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
  AddItem,
  DeleteItem,
  GetItems,
  UpdateItem,
} from '@stores-actions/item.action';
import { AddNotification } from '@stores-actions/notification.action';
import { ItemStoreService } from '@stores-services/item-store.service';

import { AuthState } from './authentication.state';

export class ItemStateModel {
  items: Item[] = [];
  selectedItem!: Item | null;
}

/**
 * Item metadata and action mappings.
 */
@State<ItemStateModel>({
  name: 'item',
  defaults: {
    items: [],
    selectedItem: null,
  },
})
@Injectable()
export class ItemState {
  constructor(
    private itemStoreService: ItemStoreService,
    private store: Store,
    private translocoService: TranslocoService,
    private apollo: Apollo
  ) {}

  @Selector()
  static getItems(state: ItemStateModel): Item[] {
    return state.items;
  }

  @Selector()
  static getSelectedItem(state: ItemStateModel): Item | null {
    return state.selectedItem;
  }

  @Action(GetItems, { cancelUncompleted: true })
  getItems(
    { getState, setState }: StateContext<ItemStateModel>,
    { filter }: GetItems
  ): Observable<Item[]> {
    return this.itemStoreService.fetchItems(filter).pipe(
      tap((result) => {
        const state = getState();
        setState({
          ...state,
          items: result,
        });
      })
    );
  }

  @Action(AddItem)
  addItem(
    { getState, patchState }: StateContext<ItemStateModel>,
    { payload }: AddItem
  ): Observable<Item> {
    return this.itemStoreService.addItem(payload).pipe(
      tap((result) => {
        const state = getState();
        patchState({
          items: [...state.items, result],
          selectedItem: result,
        });

        const message = this.translocoService.translate(
          'notificationsMessagesKeys.itemNoWasCreatedSuccessfullyKeyParam',
          {
            id: result.no,
          }
        );
        const title = this.translocoService.translate('createKey');

        this.store.dispatch(new AddNotification(message, 'success', title));
        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'items',
        });
      })
    );
  }

  @Action(UpdateItem)
  updateItem(
    { getState, setState }: StateContext<ItemStateModel>,
    { patch, no, selectedItem }: UpdateItem
  ): Observable<Item> {
    return this.itemStoreService.updateItem(no, patch).pipe(
      tap((result) => {
        const state = getState();
        const itemLineList = [...state.items];
        const itemLineIndex = itemLineList.findIndex((item) => item.no === no);
        itemLineList[itemLineIndex] = result;
        setState({
          ...state,
          items: itemLineList,
          selectedItem: selectedItem !== undefined ? selectedItem : result,
        });

        const message = this.translocoService.translate(
          'notificationsMessagesKeys.itemNoWasUpdatedSuccessfullyKeyParam',
          { id: no }
        );
        const title = this.translocoService.translate('updatedKey');

        this.store.dispatch(new AddNotification(message, 'success', title));
        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'items',
        });
      })
    );
  }

  @Action(DeleteItem)
  deleteItem(
    { getState, setState }: StateContext<ItemStateModel>,
    { no }: DeleteItem
  ): Observable<Item> {
    return this.itemStoreService.deleteItem(no).pipe(
      tap(() => {
        const state = getState();
        const filteredArray = state.items.filter((item) => item.no !== no);
        setState({
          ...state,
          items: filteredArray,
          selectedItem: filteredArray[0],
        });

        const message = this.translocoService.translate(
          'notificationsMessagesKeys.itemNoWasDeletedSuccessfullyKeyParam',
          { id: no }
        );
        const title = this.translocoService.translate('deletedKey');

        this.store.dispatch(new AddNotification(message, 'success', title));
        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'items',
        });
      })
    );
  }
}
