import { Injectable } from '@angular/core';
import { config } from '@tag/graphql';
import type {
  Area,
  GraphQLParams,
  WorkOrderFeedbackHeader,
} from '@tag/graphql';
import { Query, gql } from 'apollo-angular';

export interface Response {
  feedbacks: { items: WorkOrderFeedbackHeader[]; totalCount: number };
}

@Injectable({
  providedIn: 'root',
})
export class FeedbacksGQL extends Query<
  Response,
  GraphQLParams<'workOrderFeedbackHeader'>
> {
  document = gql(config.workOrderFeedbackHeader.query);
}
