import { Injectable } from '@angular/core';
import { PurchaseOrderLine } from '@tag/graphql';
import { PurchaseOrderLinesGQL } from '@shared/apollo/queries/purchase-order';
import { Observable, map, of } from 'rxjs';

/**
 * Service used to automate CRUD operation from the NGXS store to TAG API V2.
 *
 * @deprecated This is an internal implementation class, do not use directly.
 */
@Injectable({
  providedIn: 'root',
})
export class PurchaseOrderLineStoreService {
  constructor(private poLineGQL: PurchaseOrderLinesGQL) {}

  /**
   * Fetchs Purchase Order Line
   *
   * @param filter OData filter
   *
   * @returns Purchase Order Lines
   * @deprecated This is an internal implementation method, do not use directly.
   */
  fetchPurchaseOrderLines(filter?: string): Observable<PurchaseOrderLine[]> {
    return this.poLineGQL.watch({ filter }).valueChanges.pipe(
      map((result) => {
        return result.data.purchaseOrderLines.items;
      })
    );
  }

  /**
   * Deletes Purchase Order Line
   *
   * @param no
   * @param line
   *
   * @returns Mostly nothing.
   * @deprecated This is an internal implementation method, do not use directly.
   * @todo Add the new API call when ready
   */
  deletePurchaseOrderLine(no: string, line: number): Observable<any> {
    throw new Error('Method not implemented.');
  }

  /**
   * Adds Purchase Order Line
   *
   * @param payload
   *
   * @returns Purchase Order Line
   * @deprecated This is an internal implementation method, do not use directly.
   * @todo Add the new API call when ready
   */
  addPurchaseOrderLine(
    payload: PurchaseOrderLine
  ): Observable<PurchaseOrderLine> {
    throw new Error('Method not implemented.');
  }

  /**
   * Updates Purchase Order Line
   *
   * @param no
   * @param line
   * @param patch
   *
   * @returns Purchase Order Line
   * @deprecated This is an internal implementation method, do not use directly.
   * @todo Add the new API call when ready
   */
  updatePurchaseOrderLine(
    no: string,
    line: number,
    patch: any[]
  ): Observable<PurchaseOrderLine> {
    throw new Error('Method not implemented.');
  }
}
