import { Injectable } from '@angular/core';
import type { Facility } from '@tag/graphql';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FacilitiesGQL } from '@shared/apollo/queries/facility';

/**
 * Service used to automate CRUD operation from the NGXS store to TAG API V2.
 *
 * @deprecated This is an internal implementation class, do not use directly.
 */
@Injectable({
  providedIn: 'root',
})
export class FacilityStoreService {
  constructor(private facilitiesGQL: FacilitiesGQL) {}

  /**
   * Fetchs facility
   *
   * @param filter OData filter
   *
   * @returns facility
   * @deprecated This is an internal implementation method, do not use directly.
   */
  fetchFacilities(filter?: string): Observable<Facility[]> {
    return this.facilitiesGQL
      .watch({ filter })
      .valueChanges.pipe(map((result) => result.data.facilities.items));
  }
}
