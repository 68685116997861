import { Injectable } from '@angular/core';
import type { Item } from '@tag/graphql';
import { Operation } from 'fast-json-patch';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ItemsGQL } from '@shared/apollo/queries/item';

/**
 * Service used to automate CRUD operation from the NGXS store to TAG API V2.
 *
 * @deprecated This is an internal implementation class, do not use directly.
 */
@Injectable({
  providedIn: 'root',
})
export class ItemStoreService {
  constructor(private itemsGQL: ItemsGQL) {}

  /**
   * Fetchs item
   *
   * @param filter OData filter
   *
   * @returns item
   * @deprecated This is an internal implementation method, do not use directly.
   */
  fetchItems(filter?: string): Observable<Item[]> {
    return this.itemsGQL
      .watch({ filter })
      .valueChanges.pipe(map((result) => result.data.items.items));
  }

  /**
   * Deletes item
   *
   * @param no
   *
   * @returns Mostly nothing.
   * @deprecated This is an internal implementation method, do not use directly.
   */
  deleteItem(no: string): Observable<any> {
    throw new Error('Method not implemented.');
  }

  /**
   * Adds item
   *
   * @param payload
   *
   * @returns item
   * @deprecated This is an internal implementation method, do not use directly.
   */
  addItem(payload: Item): Observable<Item> {
    throw new Error('Method not implemented.');
  }

  /**
   * Updates item
   *
   * @param no
   * @param patch
   *
   * @returns item
   * @deprecated This is an internal implementation method, do not use directly.
   */
  updateItem(no: string, patch: Operation[]): Observable<Item> {
    throw new Error('Method not implemented.');
  }
}
