import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AddNotification } from '@stores-actions/notification.action';
import {
  AddPurchaseOrderLine,
  DeletePurchaseOrderLine,
  GetPurchaseOrderLines,
  SetSelectedPurchaseOrderLine,
  UpdateLocalPurchaseOrderLinesFromDocumentCreation,
  UpdatePurchaseOrderLine,
} from '@stores-actions/purchase-order-line.action';
import { GetPurchaseOrder } from '@stores-actions/purchase-order.action';
import { PurchaseOrderLineStoreService } from '@stores-services/purchase-order-line-store.service';

import { AuthState } from './authentication.state';
import { PurchaseOrderLine } from '@tag/graphql';

export class PurchaseOrderLineStateModel {
  purchaseOrderLines: PurchaseOrderLine[] = [];
  selectedPurchaseOrderLine!: PurchaseOrderLine | null;
  initialized = false;
}

/**
 * Purchase Order metadata and action mappings.
 *
 * @todo Add the missing endpoints from API (add, update, delete) & Update transloco messages.
 */
@State<PurchaseOrderLineStateModel>({
  name: 'pol',
  defaults: {
    purchaseOrderLines: [],
    selectedPurchaseOrderLine: null,
    initialized: false,
  },
})
@Injectable()
export class PurchaseOrderLineState {
  constructor(
    private polStoreService: PurchaseOrderLineStoreService,
    private store: Store,
    private translocoService: TranslocoService
  ) {}

  @Selector()
  static getPurchaseOrderLines(
    state: PurchaseOrderLineStateModel
  ): PurchaseOrderLine[] {
    return state.purchaseOrderLines;
  }

  @Selector()
  static getSelectedPurchaseOrderLine(
    state: PurchaseOrderLineStateModel
  ): PurchaseOrderLine | null {
    return state.selectedPurchaseOrderLine;
  }

  @Selector()
  static getInitStatus(state: PurchaseOrderLineStateModel): boolean {
    return state.initialized;
  }

  @Action(GetPurchaseOrderLines, { cancelUncompleted: true })
  getPurchaseOrderLines(
    { getState, setState }: StateContext<PurchaseOrderLineStateModel>,
    { filter }: GetPurchaseOrderLines
  ): Observable<PurchaseOrderLine[]> {
    return this.polStoreService.fetchPurchaseOrderLines(filter).pipe(
      tap((result: PurchaseOrderLine[]) => {
        const state = getState();
        setState({
          ...state,
          purchaseOrderLines: result,
          initialized: true,
        });
      })
    );
  }

  @Action(AddPurchaseOrderLine)
  addPurchaseOrderLine(
    { getState, patchState }: StateContext<PurchaseOrderLineStateModel>,
    { payload }: AddPurchaseOrderLine
  ): Observable<PurchaseOrderLine> {
    return this.polStoreService.addPurchaseOrderLine(payload).pipe(
      tap((result: PurchaseOrderLine) => {
        const state = getState();
        patchState({
          purchaseOrderLines: [...state.purchaseOrderLines, result],
          selectedPurchaseOrderLine: result,
        });

        const message = this.translocoService.translate(
          'notificationsMessagesKeys.purchaseOrderLineWasCreatedSuccessfullyKeyParam',
          {
            id: result.no,
          }
        );
        const title = this.translocoService.translate('createKey');

        this.store.dispatch(new AddNotification(message, 'success', title));
      })
    );
  }

  @Action(UpdatePurchaseOrderLine)
  updatePurchaseOrderLine(
    { getState, setState }: StateContext<PurchaseOrderLineStateModel>,
    { patch, no, line, selectedItem }: UpdatePurchaseOrderLine
  ): Observable<PurchaseOrderLine> {
    return this.polStoreService.updatePurchaseOrderLine(no, line, patch).pipe(
      tap((result: PurchaseOrderLine) => {
        const state = getState();
        const poList = [...state.purchaseOrderLines];
        const poIndex = poList.findIndex((item) => item.no === no);
        poList[poIndex] = result;
        setState({
          ...state,
          purchaseOrderLines: poList,
          selectedPurchaseOrderLine:
            selectedItem !== undefined ? selectedItem : result,
        });

        const message = this.translocoService.translate(
          'notificationsMessagesKeys.purchaseOrderLineWasUpdatedSuccessfullyKeyParam',
          {
            id: no,
          }
        );
        const title = this.translocoService.translate('updatedKey');

        this.store.dispatch(new AddNotification(message, 'success', title));
        if (result.no) this.store.dispatch(new GetPurchaseOrder(result.no));
      })
    );
  }

  @Action(DeletePurchaseOrderLine)
  deletePurchaseOrderLine(
    { getState, setState }: StateContext<PurchaseOrderLineStateModel>,
    { no, line }: DeletePurchaseOrderLine
  ): Observable<PurchaseOrderLine> {
    return this.polStoreService.deletePurchaseOrderLine(no, line).pipe(
      tap(() => {
        const state = getState();
        const filteredArray = state.purchaseOrderLines.filter(
          (item) => item.no !== no
        );
        setState({
          ...state,
          purchaseOrderLines: filteredArray,
          selectedPurchaseOrderLine: null,
        });

        const message = this.translocoService.translate(
          'notificationsMessagesKeys.purchaseOrderLineWasDeletedSuccessfullyKeyParam',
          {
            id: no,
          }
        );
        const title = this.translocoService.translate('deletedKey');

        this.store.dispatch(new AddNotification(message, 'success', title));
      })
    );
  }

  @Action(UpdateLocalPurchaseOrderLinesFromDocumentCreation, {
    cancelUncompleted: true,
  })
  updateLocalPurchaseOrderLinesFromDocumentCreation(
    { getState, setState }: StateContext<PurchaseOrderLineStateModel>,
    { pols }: UpdateLocalPurchaseOrderLinesFromDocumentCreation
  ): void {
    const state = getState();
    const polNumbers = [
      ...new Set(pols.map((pol: PurchaseOrderLine) => pol.no)),
    ] as string[];
    const filteredArray = state.purchaseOrderLines.filter(
      (x) => !polNumbers.includes(x.no || '')
    );
    setState({
      ...state,
      purchaseOrderLines: [...filteredArray, ...pols],
    });
  }

  @Action(SetSelectedPurchaseOrderLine, { cancelUncompleted: true })
  setSelectedPurchaseOrderLine(
    { getState, setState }: StateContext<PurchaseOrderLineStateModel>,
    { payload }: SetSelectedPurchaseOrderLine
  ): void {
    const state = getState();
    setState({
      ...state,
      selectedPurchaseOrderLine: payload,
    });
  }
}
