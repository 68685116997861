import { Injectable } from '@angular/core';
import type { FinishedWorkOrder } from '@tag/graphql';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FinishedWorkOrdersGQL } from '@shared/apollo/queries/finished-work-order';

/**
 * Service used to automate CRUD operation from the NGXS store to TAG API V2.
 *
 * @deprecated This is an internal implementation class, do not use directly.
 */
@Injectable({
  providedIn: 'root',
})
export class FinishedWorkOrderStoreService {
  constructor(private finishedWorkOrdersGQL: FinishedWorkOrdersGQL) {}

  /**
   * Fetch one specific finished work order
   *
   * @param no  finished work order no
   *
   * @returns work order
   * @deprecated This is an internal implementation method, do not use directly.
   */
  fetchFinishedWorkOrder(no: string): Observable<FinishedWorkOrder> {
    return this.finishedWorkOrdersGQL
      .watch({ no })
      .valueChanges.pipe(
        map((result) => result.data.finishedWorkOrders.items[0])
      );
  }

  /**
   * Fetchs work order
   *
   * @param filter OData filter
   *
   * @returns work order
   * @deprecated This is an internal implementation method, do not use directly.
   */
  fetchFinishedWorkOrders(filter?: string): Observable<FinishedWorkOrder[]> {
    return this.finishedWorkOrdersGQL
      .watch({ filter })
      .valueChanges.pipe(map((result) => result.data.finishedWorkOrders.items));
  }
}
