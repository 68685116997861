import type { Consumable } from '@tag/graphql';
import { Operation } from 'fast-json-patch';

/**
 * Add Consumable
 *
 * @param payload Consumable object
 */
export class AddConsumable {
  static readonly type = '[Consumable] Add';

  constructor(public payload: Consumable) {}
}

/**
 * Get all Consumables
 *
 * @param filter OData filter
 */
export class GetConsumables {
  static readonly type = '[Consumable] Get';

  constructor(public filter?: string) {}
}

/**
 * Update Consumable Line, this method will dynamically update the cache Consumable list and selected Consumable.
 *
 * @param no Consumable No
 * @param patch JSON patch object used to update the Consumable. Import Operation[] from fast-json-patch
 * @param selectedItem Force the selected item to change after the update. By default, it will take the updated object.
 */
export class UpdateConsumable {
  static readonly type = '[Consumable] Update';

  constructor(public no: string, public patch: Operation[]) {}
}

/**
 * Delete Consumable, this method will dynamically update the cache Consumable list and set selected Consumable to null.
 *
 * @param no Consumable No
 */
export class DeleteConsumable {
  static readonly type = '[Consumable] Delete';

  constructor(public no: string) {}
}
