import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import {
  Action,
  Selector,
  State,
  StateContext,
  Store,
  createSelector,
} from '@ngxs/store';
import type { Equipment } from '@tag/graphql';
import { Apollo } from 'apollo-angular';
import { produce } from 'immer';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApolloService } from '@shared/apollo/apollo.service';
import {
  AddUpdateEquipment,
  DeleteEquipment,
  DeleteEquipments,
  GetEquipment,
  GetEquipments,
  SetSelectedEquipment,
  SetSelectedEquipments,
} from '@stores-actions/equipment.action';
import { AddNotification } from '@stores-actions/notification.action';
import { EquipmentStoreService } from '@stores-services/equipment-store.service';

export class EquipmentStateModel {
  equipments: Equipment[] = [];
  formSelectedEquipment!: Equipment | null;
  selectedEquipment!: Equipment | null;
  selectedEquipments: Equipment[] = [];
}

/**
 * Equipments metadata and action mappings.
 */
@State<EquipmentStateModel>({
  name: 'equipment',
  defaults: {
    equipments: [],
    formSelectedEquipment: null,
    selectedEquipment: null,
    selectedEquipments: [],
  },
})
@Injectable()
export class EquipmentState {
  constructor(
    private equipmentStoreService: EquipmentStoreService,
    private store: Store,
    private translocoService: TranslocoService,
    private apollo: Apollo,
    private apolloUtils: ApolloService
  ) {}

  static getEquipmentsByArea(
    no: string
  ): (state: EquipmentStateModel) => Equipment[] {
    return createSelector([EquipmentState], (state: EquipmentStateModel) =>
      state.equipments.filter((eq) => eq.area === no)
    );
  }

  static getEquipmentsByFacility(
    no: string
  ): (state: EquipmentStateModel) => Equipment[] {
    return createSelector([EquipmentState], (state: EquipmentStateModel) =>
      state.equipments.filter((eq) => eq.facility === no)
    );
  }

  static getEquipment(
    id: string
  ): (state: EquipmentStateModel) => Equipment | undefined {
    return createSelector([EquipmentState], (state: EquipmentStateModel) =>
      state.equipments.find((eq) => eq.id === id)
    );
  }

  @Selector()
  static getEquipments(state: EquipmentStateModel): Equipment[] {
    return state.equipments;
  }

  @Selector()
  static getSelectedEquipments(state: EquipmentStateModel): Equipment[] {
    return state.selectedEquipments;
  }

  @Selector()
  static getSelectedEquipment(state: EquipmentStateModel): Equipment | null {
    return state.selectedEquipment;
  }

  @Selector()
  static getFormSelectedEquipment(
    state: EquipmentStateModel
  ): Equipment | null {
    return state.formSelectedEquipment;
  }

  @Selector()
  static getReviewEquipments(state: EquipmentStateModel): Equipment[] {
    return state.equipments.filter((eq) => eq.pendingApproval);
  }

  @Selector()
  static getLevel1Equipments(state: EquipmentStateModel): Equipment[] {
    return state.equipments.map((eq) => ({ ...eq, code: eq.id }));
  }

  @Action(GetEquipments, { cancelUncompleted: true })
  getEquipments(
    { patchState }: StateContext<EquipmentStateModel>,
    { filter }: GetEquipments
  ): Observable<Equipment[]> {
    return this.equipmentStoreService.fetchEquipments(filter).pipe(
      tap((result) => {
        patchState({
          equipments: result,
        });
      })
    );
  }

  @Action(GetEquipment, { cancelUncompleted: true })
  getEquipment(
    ctx: StateContext<EquipmentStateModel>,
    { id }: GetEquipment
  ): Observable<Equipment[]> {
    const filter = `ID eq '${id}'`;

    return this.equipmentStoreService.fetchEquipments(filter).pipe(
      tap((result) => {
        ctx.setState(
          produce((draft) => {
            const i = draft.equipments.findIndex((eq) => eq.id === id);
            if (i > -1) draft.equipments[i] = result[0];
            else draft.equipments.push(result[0]);
            draft.selectedEquipment = result[0];
          })
        );
      })
    );
  }

  @Action(AddUpdateEquipment)
  addUpdateEquipment(
    ctx: StateContext<EquipmentStateModel>,
    { payload }: AddUpdateEquipment
  ): Observable<Equipment> {
    return this.equipmentStoreService.addUpdateEquipment(payload).pipe(
      tap((result) => {
        ctx.setState(
          produce((draft) => {
            draft.equipments.push(result);
            draft.selectedEquipment = result;
          })
        );

        const message = this.translocoService.translate(
          payload.ID
            ? 'notificationsMessagesKeys.equipmentIdWasUpdatedSuccessfullyKeyParam'
            : 'notificationsMessagesKeys.equipmentIdWasCreatedSuccessfullyKeyParam',
          {
            id: result.id,
          }
        );
        const title = this.translocoService.translate('createKey');
        ctx.dispatch(new AddNotification(message, 'success', title));

        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'equipments',
        });
        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'equipmentTrees',
        });
      })
    );
  }

  @Action(DeleteEquipment)
  deleteEquipment(
    ctx: StateContext<EquipmentStateModel>,
    { id }: DeleteEquipment
  ): Observable<Equipment> {
    return this.equipmentStoreService.deleteEquipment(id).pipe(
      tap(() => {
        ctx.setState(
          produce((draft) => {
            const i = draft.equipments.findIndex((eq) => eq.id === id);
            if (i > -1) draft.equipments.splice(i, 1);
          })
        );

        const message = this.translocoService.translate(
          'notificationsMessagesKeys.equipmentIdWasDeletedSuccessfullyKeyParam',
          { id }
        );
        const title = this.translocoService.translate('deletedKey');

        ctx.dispatch(new AddNotification(message, 'success', title));
        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'equipments',
        });
        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'equipmentTrees',
        });
      })
    );
  }

  @Action(DeleteEquipments)
  deleteEquipments(
    ctx: StateContext<EquipmentStateModel>,
    { ids }: DeleteEquipments
  ): Observable<Equipment[]> {
    return this.equipmentStoreService.deleteEquipments(ids).pipe(
      tap(() => {
        ctx.setState(
          produce((draft) => {
            ids.forEach((id) => {
              const i = draft.equipments.findIndex((eq) => eq.id === id);
              if (i > -1) draft.equipments.splice(i, 1);
            });
          })
        );

        const message = this.translocoService.translate(
          'notificationsMessagesKeys.equipmentIdWasDeletedSuccessfullyKeyParam',
          {
            id: ids.join(','),
          }
        );
        const title = this.translocoService.translate('deletedKey');
        ctx.dispatch(new AddNotification(message, 'success', title));
        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'equipments',
        });
        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'equipmentTrees',
        });
      })
    );
  }

  @Action(SetSelectedEquipment)
  setSelectedEquipment(
    { patchState }: StateContext<EquipmentStateModel>,
    { payload }: SetSelectedEquipment
  ): void {
    patchState({
      selectedEquipment: payload,
    });
  }

  @Action(SetSelectedEquipments)
  setSelectedEquipments(
    { patchState }: StateContext<EquipmentStateModel>,
    { payload }: SetSelectedEquipments
  ): void {
    patchState({
      selectedEquipments: payload,
    });
  }
}
