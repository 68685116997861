import type { Facility } from '@tag/graphql';
import { Operation } from 'fast-json-patch';

/**
 * Add Facility
 *
 * @param payload Facility object
 */
export class AddFacility {
  static readonly type = '[Facility] Add';

  constructor(public payload: Facility) {}
}

/**
 * Get all Facilitys
 *
 * @param filter OData filter
 */
export class GetFacilities {
  static readonly type = '[Facility] Get';

  constructor(public filter?: string, public bustCache?: boolean) {}
}

/**
 * Update Facility Line, this method will dynamically update the cache Facility list and selected Facility.
 *
 * @param code Facility No
 * @param patch JSON patch object used to update the Facility. Import Operation[] from fast-json-patch
 * @param selectedItem Force the selected item to change after the update. By default, it will take the updated object.
 */
export class UpdateFacility {
  static readonly type = '[Facility] Update';

  constructor(
    public code: string,
    public patch: Operation[],
    public selectedItem?: Facility
  ) {}
}

/**
 * Delete Facility, this method will dynamically update the cache Facility list and set selected Facility to null.
 *
 * @param code Facility code
 */
export class DeleteFacility {
  static readonly type = '[Facility] Delete';

  constructor(public code: string) {}
}

/**
 * Set selected Facility Line
 *
 * @param payload New Facility Line full object. Summary is not supported.
 */
export class SetSelectedFacility {
  static readonly type = '[Facility] Set';

  constructor(public payload: Facility | null) {}
}
