import { Injectable } from '@angular/core';
import type { Equipment } from '@tag/graphql';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { EquipmentsGQL } from '@shared/apollo/queries/equipment';
import { ApiService } from '@services/api.service';
import { EquipmentPost } from '@api/types';

/**
 * Service used to automate CRUD operation with TAG API V2 from NGXS store.
 *
 * @deprecated This is an internal implementation class, do not use directly.
 */
@Injectable({
  providedIn: 'root',
})
export class EquipmentStoreService {
  constructor(private api: ApiService, private equipmentsGQL: EquipmentsGQL) {}

  /**
   * Fetchs equipment
   *
   * @param filter OData filter
   *
   * @returns equipment
   * @deprecated This is an internal implementation method, do not use directly.
   */
  fetchEquipments(filter?: string): Observable<Equipment[]> {
    return this.equipmentsGQL
      .watch({ filter })
      .valueChanges.pipe(
        map((result) => result.data.equipments.items as Equipment[])
      );
  }

  /**
   * Deletes equipment
   *
   * @param id
   *
   * @returns Mostly nothing.
   * @deprecated This is an internal implementation method, do not use directly.
   */
  deleteEquipment(id: string): Observable<any> {
    throw new Error('Method not implemented.');
  }

  /**
   * Deletes multiple equipments
   *
   * @param ids
   *
   * @returns Mostly nothing.
   * @deprecated This is an internal implementation method, do not use directly.
   */
  deleteEquipments(ids: string[]): Observable<any> {
    throw new Error('Method not implemented.');
  }

  /**
   * Adds equipment
   *
   * @param payload
   *
   * @returns equipment
   * @deprecated This is an internal implementation method, do not use directly.
   */
  // Add the Code Unit
  addUpdateEquipment(payload: EquipmentPost): Observable<Equipment> {
    return this.api.post('/equipments', payload);
  }
}
