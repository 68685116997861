import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingScreenService {
  loadingStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private loadingValue = false;

  get loading(): boolean {
    return this.loadingValue;
  }

  set loading(flag: boolean) {
    this.loadingValue = flag;
    this.loadingStatus.next(flag);
  }

  startLoading(): void {
    this.loading = true;
  }

  stopLoading(): void {
    this.loading = false;
  }
}
