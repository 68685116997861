import type { Item } from '@tag/graphql';
import { Operation } from 'fast-json-patch';

/**
 * Add Item
 *
 * @param payload Item object
 */
export class AddItem {
  static readonly type = '[Item] Add';

  constructor(public payload: Item) {}
}

/**
 * Get all Items
 *
 * @param filter OData filter
 */
export class GetItems {
  static readonly type = '[Item] Get';

  constructor(public filter?: string) {}
}

/**
 * Update Item Line, this method will dynamically update the cache Item list and selected Item.
 *
 * @param no Item No
 * @param patch JSON patch object used to update the Item. Import Operation[] from fast-json-patch
 * @param selectedItem Force the selected item to change after the update. By default, it will take the updated object.
 */
export class UpdateItem {
  static readonly type = '[Item] Update';

  constructor(
    public no: string,
    public patch: Operation[],
    public selectedItem?: Item
  ) {}
}

/**
 * Delete Item, this method will dynamically update the cache Item list and set selected Item to null.
 *
 * @param no Item No
 */
export class DeleteItem {
  static readonly type = '[Item] Delete';

  constructor(public no: string) {}
}
