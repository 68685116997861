import { Injectable } from '@angular/core';
import {
  Action,
  Selector,
  State,
  StateContext,
  Store,
  createSelector,
} from '@ngxs/store';
import type { Area } from '@tag/graphql';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { GetAreas } from '@stores-actions/area.action';
import { AreaStoreService } from '@stores-services/area-store.service';

export class AreaStateModel {
  areas: Area[] = [];
  initialized = false;
}

/**
 * Area metadata and action mappings.
 */
@State<AreaStateModel>({
  name: 'area',
  defaults: {
    areas: [],
    initialized: false,
  },
})
@Injectable()
export class AreaState {
  constructor(
    private areaStoreService: AreaStoreService,
    private store: Store
  ) {}

  static getAreasByFacility(code?: string): (state: AreaStateModel) => Area[] {
    return createSelector([AreaState], (state: AreaStateModel) =>
      state.areas.filter((area) => area.facility === code)
    );
  }

  @Selector()
  static getAreas(state: AreaStateModel): Area[] {
    return state.areas;
  }

  @Action(GetAreas, { cancelUncompleted: true })
  getAreas(
    { getState, setState }: StateContext<AreaStateModel>,
    { filter, bustCache }: GetAreas
  ): Observable<Area[]> {
    let state = getState();
    if (state.initialized && !bustCache) return of(state.areas);
    return this.areaStoreService.fetchAreas(filter).pipe(
      tap((result) => {
        state = getState();
        setState({
          ...state,
          areas: result,
          initialized: true,
        });
      })
    );
  }
}
