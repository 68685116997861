/* eslint-disable @typescript-eslint/naming-convention */

import { Injectable } from '@angular/core';
import type { CommentSheet, WorkOrderFeedbackHeader } from '@tag/graphql';
import { Operation } from 'fast-json-patch';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CommentsGQL } from '@shared/apollo/queries/comment';
import { FeedbacksGQL } from '@shared/apollo/queries/feedback';
import { FeedbackStateObject } from '@stores-states/feedback.state';
import { ApiService } from '@services/api.service';

/**
 * Service used to automate CRUD operation from the NGXS store to TAG API V2.
 *
 */
@Injectable({
  providedIn: 'root',
})
export class FeedbackStoreService {
  constructor(
    private feedbacksGQL: FeedbacksGQL,
    private commentsGQL: CommentsGQL,
    private api: ApiService
  ) {}

  /**
   * Fetchs Feedback
   *
   * @param filter OData filter
   *
   * @returns Feedback
   */
  fetchFeedbacks(filter?: string): Observable<FeedbackStateObject[]> {
    return this.feedbacksGQL
      .watch({ filter })
      .valueChanges.pipe(
        map((fbs) => this.convertFeedbackStateObjects(fbs.data.feedbacks.items))
      );
  }

  /**
   * Fetchs Comments
   *
   * @param filter OData filter
   *
   * @returns Feedback
   */
  fetchComments(filter?: string): Observable<FeedbackStateObject[]> {
    return this.commentsGQL
      .watch({ filter })
      .valueChanges.pipe(
        map((coms) =>
          this.convertCommentsToFeedbackStateObjects(coms.data.comments.items)
        )
      );
  }

  /**
   * Fetch one Feedback
   *
   * @param tableName Table name  (Order, OrderLine, FinishedOrder, FinishedLine)
   * @param documentType Source Document Type (Planned, Released, Finished)
   * @param documentNo Source Document line no if any or 0.
   * @param documentLineNo Feedback unique ID
   * @param lineNo Feedback unique ID
   *
   * @returns Feedback
   * @deprecated This is an internal implementation method, do not use directly.
   */
  fetchFeedback(
    tableName: 'Order' | 'OrderLine' | 'FinishedOrder' | 'FinishedLine',
    documentType: 'Planned' | 'Released' | 'Finished',
    documentNo: string,
    documentLineNo: number,
    feedbackType:
      | 'General'
      | 'As Found'
      | 'Repairs'
      | 'As Left'
      | 'Recommendations',
    lineNo: number
  ): Observable<FeedbackStateObject> {
    const filter = `Table_Name eq '${tableName}' and Document_Type eq '${documentType}' and Document_No eq 
    '${documentNo}' and Document_Line_No eq ${documentLineNo} and Feedback_Type eq '${feedbackType}' and Line_No eq ${lineNo}`;

    return this.feedbacksGQL
      .watch({ filter })
      .valueChanges.pipe(
        map(
          (fbs) => this.convertFeedbackStateObjects(fbs.data.feedbacks.items)[0]
        )
      );
  }

  /**
   * Fetch one Comment
   *
   *
   * @param tableName Table name  (Order, OrderLine, FinishedOrder, FinishedLine)
   * @param no Source Document line no if any or 0.
   * @param lineNo Comment unique ID
   * @returns Comment
   * @deprecated This is an internal implementation method, do not use directly.
   */
  fetchComment(
    company: string,
    tableName: 'Order' | 'FinishedOrder' | 'Requests',
    no: string,
    lineNo: number
  ): Observable<FeedbackStateObject> {
    const filter = `Table_Name eq '${tableName}' and No eq '${no}' and Line_No eq ${lineNo}`;

    return this.commentsGQL
      .watch({ filter })
      .valueChanges.pipe(
        map(
          (coms) =>
            this.convertCommentsToFeedbackStateObjects(
              coms.data.comments.items
            )[0]
        )
      );
  }

  /**
   * Deletes Feedback
   *
   * @param tableName Table name  (Order, OrderLine, FinishedOrder, FinishedLine)
   * @param documentType Source Document Type (Planned, Released, Finished)
   * @param documentNo Source Document line no if any or 0.
   * @param documentLineNo Feedback unique ID
   * @param lineNo Feedback unique ID
   * @param company Currently selected nav company
   * @returns Mostly nothing.
   * @deprecated This is an internal implementation method, do not use directly.
   */
  deleteFeedback(
    tableName: string,
    documentType: string,
    documentNo: string,
    documentLineNo: number,
    feedbackType: string,
    lineNo: number
  ): Observable<any> {
    return this.api.delete(
      `/comments/${tableName}-${documentType}-${documentNo}-${documentLineNo}-${feedbackType}-${lineNo}`
    );
  }

  /**
   * Deletes Comment
   *
   * @param company Currently selected nav company
   * @param tableName Table name  (Order, OrderLine, FinishedOrder, FinishedLine)
   * @param no Source Document line no if any or 0.
   * @param lineNo Comment unique ID
   * @returns Comment
   * @returns Mostly nothing.
   * @deprecated This is an internal implementation method, do not use directly.
   */
  deleteComment(
    tableName: string,
    no: string,
    lineNo: number
  ): Observable<any> {
    return this.api.delete(`/comments/${tableName}-${no}-${lineNo}`);
  }

  /**
   * Gets feedback state object id
   *
   * @param documentNo
   * @param documentLineNo
   * @param lineNo
   * @returns feedback state object id
   */
  getFeedbackStateObjectId(
    documentNo: string,
    documentLineNo: number,
    lineNo: number,
    type: 'comment' | 'feedback'
  ): string {
    return documentNo + documentLineNo + lineNo + type;
  }

  /**
   * Gets feedback source id
   *
   * @param documentNo
   * @param documentLineNo
   * @returns feedback source id
   */
  getFeedbackDocumentId(
    documentNo: string,
    type: 'feedback' | 'comment' | 'both',
    documentLineNo?: number
  ): string {
    return documentNo + (documentLineNo ?? '') + type;
  }

  /**
   * Generates object not in store error
   *
   * @param id
   * @returns object not in store error
   */
  generateObjectNotInStoreError(id: string): any {
    console.error(
      'Object with id ' + id + ' is not found in the Feedback Store.'
    );
    return null;
  }

  /**
   * Converts comments to feedback state objects
   *
   * @param comments
   * @returns feedback state objects
   */
  convertCommentsToFeedbackStateObjects(
    comments: CommentSheet[]
  ): FeedbackStateObject[] {
    const coms = comments.map(
      (com): FeedbackStateObject =>
        ({
          ...com,
          id: this.getFeedbackStateObjectId(
            com.no ?? '',
            0,
            com.lineNo ?? 0,
            'comment'
          ),
          type: 'comment',
          documentNo: com.no,
          documentLineNo: 0,
          extendedFeedback: false,
          equipmentId: com.equipId,
          feedback: com.description,
          feedbackType: 'Comment',
        } as unknown as FeedbackStateObject)
    );
    return coms;
  }

  /**
   * Converts feedbacks to feedback state objects
   *
   * @param feedbacks
   * @returns feedback state objects
   */
  convertFeedbackStateObjects(
    feedbacks: WorkOrderFeedbackHeader[]
  ): FeedbackStateObject[] {
    return feedbacks.map((fb) => ({
      ...fb,
      id: this.getFeedbackStateObjectId(
        fb.documentNo ?? '',
        fb.documentLineNo ?? 0,
        fb.lineNo ?? 0,
        'feedback'
      ),
      type: 'feedback',
      no: fb.documentNo,
      equipId: fb.equipmentId,
      description: fb.feedback,
      documentLineNo: fb.documentLineNo ?? 0,
      extendedFeedback: fb.extendedFeedback ?? false,
    })) as FeedbackStateObject[];
  }
}
