import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import type { FinishedWorkOrder } from '@tag/graphql';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
  GetFinishedWorkOrder,
  GetFinishedWorkOrders,
  GetSummaryFinishedWorkOrders,
  SetSelectedFinishedWorkOrder,
  UpdateLocalFinishWorkOrderStore,
} from '@stores-actions/finished-work-order.action';
import { FinishedWorkOrderStoreService } from '@stores-services/finished-work-order-store.service';

export class FinishedWorkOrderStateModel {
  finishedWorkOrders: FinishedWorkOrder[] = [];
  selectedFinishedWorkOrder!: FinishedWorkOrder | null;
  initialized = false;
}

/**
 * Work Order Lines metadata and action mappings.
 */
@State<FinishedWorkOrderStateModel>({
  name: 'finishedWorkOrder',
  defaults: {
    finishedWorkOrders: [],
    selectedFinishedWorkOrder: null,
    initialized: false,
  },
})
@Injectable()
export class FinishedWorkOrderState {
  constructor(
    private finishedWorkOrderStoreService: FinishedWorkOrderStoreService
  ) {}

  @Selector()
  static getFinishedWorkOrders(
    state: FinishedWorkOrderStateModel
  ): FinishedWorkOrder[] {
    return state.finishedWorkOrders;
  }

  @Selector()
  static getSelectedfinishedWorkOrder(
    state: FinishedWorkOrderStateModel
  ): FinishedWorkOrder | null {
    return state.selectedFinishedWorkOrder;
  }

  @Selector()
  static getInitStatus(state: FinishedWorkOrderStateModel): boolean {
    return state.initialized;
  }

  @Action(GetFinishedWorkOrder, { cancelUncompleted: true })
  getFinishedWorkOrder(
    { getState, setState }: StateContext<FinishedWorkOrderStateModel>,
    { no, bustCache }: GetFinishedWorkOrder
  ): Observable<FinishedWorkOrder> {
    let state = getState();
    let finishedWorkOrderLineList = [...state.finishedWorkOrders];
    const finishedWorkOrderLineIndex = finishedWorkOrderLineList.findIndex(
      (item) => item.no === no
    );

    if (state.initialized && !bustCache && ~finishedWorkOrderLineIndex)
      return of(state.finishedWorkOrders[finishedWorkOrderLineIndex]);
    return this.finishedWorkOrderStoreService.fetchFinishedWorkOrder(no).pipe(
      tap((result) => {
        state = getState();
        finishedWorkOrderLineList = state.finishedWorkOrders.filter(
          (wo) => wo.no !== no
        );
        finishedWorkOrderLineList = [...finishedWorkOrderLineList, result];
        setState({
          ...state,
          finishedWorkOrders: finishedWorkOrderLineList,
          selectedFinishedWorkOrder: result,
        });
      })
    );
  }

  @Action(GetFinishedWorkOrders, { cancelUncompleted: true })
  getFinishedWorkOrders(
    { getState, setState }: StateContext<FinishedWorkOrderStateModel>,
    { filter, bustCache }: GetFinishedWorkOrders
  ): Observable<FinishedWorkOrder[]> {
    let state = getState();
    if (state.initialized && !bustCache) return of(state.finishedWorkOrders);
    return this.finishedWorkOrderStoreService
      .fetchFinishedWorkOrders(filter)
      .pipe(
        tap((result) => {
          state = getState();
          setState({
            ...state,
            finishedWorkOrders: result,
            initialized: true,
          });
        })
      );
  }

  @Action(GetSummaryFinishedWorkOrders, { cancelUncompleted: true })
  getSummaryFinishedWorkOrders(
    { getState, setState }: StateContext<FinishedWorkOrderStateModel>,
    { filter, bustCache }: GetFinishedWorkOrders
  ): Observable<FinishedWorkOrder[]> {
    let state = getState();
    if (state.initialized && !bustCache) return of(state.finishedWorkOrders);
    return this.finishedWorkOrderStoreService
      .fetchFinishedWorkOrders(filter)
      .pipe(
        tap((result) => {
          state = getState();
          setState({
            ...state,
            finishedWorkOrders: result,
            initialized: true,
          });
        })
      );
  }

  @Action(UpdateLocalFinishWorkOrderStore)
  updateLocalFinishWorkOrderStore(
    { getState, setState }: StateContext<FinishedWorkOrderStateModel>,
    { fwos }: UpdateLocalFinishWorkOrderStore
  ): void {
    const state = getState();
    const fwoList = [...state.finishedWorkOrders];

    fwos.forEach((fwo) => {
      const i = state.finishedWorkOrders.findIndex((fb) => fb.no === fwo.no);
      if (~i) {
        fwoList[i] = fwo;
      } else {
        fwoList.push(fwo);
      }
    });

    setState({
      ...state,
      finishedWorkOrders: fwoList,
    });
  }

  @Action(SetSelectedFinishedWorkOrder)
  setSelectedFinishedWorkOrder(
    { getState, setState }: StateContext<FinishedWorkOrderStateModel>,
    { payload }: SetSelectedFinishedWorkOrder
  ): void {
    const state = getState();
    setState({
      ...state,
      selectedFinishedWorkOrder: payload,
    });
  }
}
