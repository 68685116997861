import { Injectable } from '@angular/core';
import type { Area } from '@tag/graphql';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AreasGQL } from '@shared/apollo/queries/area';

/**
 * Service used to automate CRUD operation from the NGXS store to TAG API V2.
 *
 * @deprecated This is an internal implementation class, do not use directly.
 */
@Injectable({
  providedIn: 'root',
})
export class AreaStoreService {
  constructor(private areasGQL: AreasGQL) {}

  /**
   * Fetchs area
   *
   * @param filter OData filter
   *
   * @returns area
   * @deprecated This is an internal implementation method, do not use directly.
   */
  fetchAreas(filter?: string): Observable<Area[]> {
    return this.areasGQL
      .watch({ filter })
      .valueChanges.pipe(map((result) => result.data.areas.items));
  }
}
