import { Injectable } from '@angular/core';
import {
  Action,
  Selector,
  State,
  StateContext,
  Store,
  createSelector,
} from '@ngxs/store';
import type { CommentSheet, WorkOrderFeedbackHeader } from '@tag/graphql';
import { Apollo } from 'apollo-angular';
import { deepClone } from 'fast-json-patch';
import { Observable, from, of } from 'rxjs';
import { mergeAll, tap } from 'rxjs/operators';

import { ApolloService } from '@shared/apollo/apollo.service';
import {
  DeleteFeedback,
  GetFeedback,
  GetFeedbacksByDocument,
  GetFeedbacksByEquipment,
  RemoveCachedFeedbacks,
} from '@stores-actions/feedback.action';
import { FeedbackStoreService } from '@stores-services/feedback-store.service';

import { AuthState } from './authentication.state';

// @ts-ignore
export interface FeedbackStateObject
  extends WorkOrderFeedbackHeader,
    CommentSheet {
  id: string;
  type: 'feedback' | 'comment';
  binaryNegativeValue?: string;
  binaryPositiveValue?: string;
  reasonCode?: string;
}

export class FeedbackStateModel {
  feedbacks: FeedbackStateObject[] = [];
}

/**
 * Feedbacks metadata and action mappings.
 */
@State<FeedbackStateModel>({
  name: 'feedback',
  defaults: {
    feedbacks: [],
  },
})
@Injectable()
export class FeedbackState {
  constructor(
    private feedbackStoreService: FeedbackStoreService,
    private store: Store,
    private apollo: Apollo,
    private apolloUtils: ApolloService
  ) {}

  static getFeedbacksByDocument(
    documentNo: string,
    documentLineNo?: number
  ): (state: FeedbackStateModel) => FeedbackStateObject[] {
    return createSelector([FeedbackState], (state: FeedbackStateModel) => {
      let feeds = state.feedbacks.filter(
        (feed) => feed.documentNo === documentNo
      );
      if (documentLineNo)
        feeds = feeds.filter((feed) => feed.documentLineNo === documentLineNo);
      return feeds;
    });
  }

  static getFeedbacksByEquipment(
    equipmentId: string
  ): (state: FeedbackStateModel) => FeedbackStateObject[] {
    return createSelector([FeedbackState], (state: FeedbackStateModel) =>
      state.feedbacks.filter((feed) => feed.equipmentId === equipmentId)
    );
  }

  @Selector()
  static getFeedbacks(state: FeedbackStateModel): FeedbackStateObject[] {
    return state.feedbacks;
  }

  @Action(GetFeedbacksByDocument, { cancelUncompleted: true })
  getFeedbacksByDocument(
    { getState, setState }: StateContext<FeedbackStateModel>,
    { documentNo, type, documentLineNo }: GetFeedbacksByDocument
  ): Observable<FeedbackStateObject[]> {
    let fbFilter = `Document_No eq '${documentNo}'`;
    if (documentLineNo)
      fbFilter += ` and Document_Line_No eq ${documentLineNo}`;
    const comFilter = `No eq '${documentNo}'`;

    let obs$ = [this.feedbackStoreService.fetchFeedbacks(fbFilter)];
    if (type === 'comment')
      obs$ = [this.feedbackStoreService.fetchComments(comFilter)];
    if (type === 'both')
      obs$.push(this.feedbackStoreService.fetchComments(comFilter));

    return from(obs$).pipe(
      mergeAll(),
      tap((result) => {
        const state = getState();
        let reqs: FeedbackStateObject[] = deepClone(state.feedbacks);

        reqs = reqs.filter((req) => !result.some((res) => res.id === req.id));

        setState({
          feedbacks: [...reqs, ...result],
        });
      })
    );
  }

  @Action(GetFeedbacksByEquipment, { cancelUncompleted: true })
  getFeedbacksByEquipment(
    { getState, setState }: StateContext<FeedbackStateModel>,
    { equipmentId, type }: GetFeedbacksByEquipment
  ): Observable<FeedbackStateObject[]> {
    let state = getState();

    const fbFilter = `Equipment_ID eq '${equipmentId}'`;
    const comFilter = `Equip_ID eq '${equipmentId}'`;

    let obs$ = [this.feedbackStoreService.fetchFeedbacks(fbFilter)];
    if (type === 'comment')
      obs$ = [this.feedbackStoreService.fetchComments(comFilter)];
    if (type === 'both')
      obs$.push(this.feedbackStoreService.fetchComments(comFilter));

    return from(obs$).pipe(
      mergeAll(),
      tap((result) => {
        state = getState();
        let reqs: FeedbackStateObject[] = deepClone(state.feedbacks);

        reqs = reqs.filter((req) => !result.some((res) => res.id === req.id));

        setState({
          feedbacks: [...reqs, ...result],
        });

        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'requests',
        });
      })
    );
  }

  @Action(GetFeedback, { cancelUncompleted: true })
  getFeedback(
    { getState, setState }: StateContext<FeedbackStateModel>,
    {
      tableName,
      documentType,
      documentNo,
      documentLineNo,
      feedbackType,
      lineNo,
    }: GetFeedback
  ): Observable<FeedbackStateObject> {
    return this.feedbackStoreService
      .fetchFeedback(
        tableName,
        documentType,
        documentNo,
        documentLineNo,
        feedbackType,
        lineNo
      )
      .pipe(
        tap((result) => {
          const state = getState();
          const feedbackList = [...state.feedbacks];

          const feedbackIndex = feedbackList.findIndex(
            (item) => item.id === result.id
          );
          if (~feedbackIndex) feedbackList[feedbackIndex] = result;
          else feedbackList.push(result);
          setState({
            ...state,
            feedbacks: feedbackList,
          });
        })
      );
  }

  @Action(DeleteFeedback)
  deleteFeedback(
    { getState, setState }: StateContext<FeedbackStateModel>,
    { id }: DeleteFeedback
  ): Observable<void> {
    let state = getState();

    const storeObj = state.feedbacks.find((fb) => fb.id === id);
    if (!storeObj) return of();

    let obs$ = this.feedbackStoreService.deleteFeedback(
      storeObj.tableName ?? '',
      storeObj.documentType ?? '',
      storeObj.documentNo ?? '',
      storeObj.documentLineNo ?? 0,
      storeObj.feedbackType ?? '',
      storeObj.lineNo ?? 0
    );
    if (storeObj.type === 'comment')
      obs$ = this.feedbackStoreService.deleteComment(
        storeObj.tableName ?? '',
        storeObj.no ?? '',
        storeObj.lineNo ?? 0
      );

    return obs$.pipe(
      tap(() => {
        state = getState();
        const filteredArray = state.feedbacks.filter((item) => item.id !== id);
        setState({
          feedbacks: filteredArray,
        });

        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'feedbacks',
        });
        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'comments',
        });
      })
    );
  }

  @Action(RemoveCachedFeedbacks)
  removeCachedFeedbacks(
    { getState, setState }: StateContext<FeedbackStateModel>,
    { workOrderNo, lineNo }: RemoveCachedFeedbacks
  ): void {
    const state = getState();
    const filteredArray = state.feedbacks.filter(
      (item) => !item.id.startsWith(`${workOrderNo}${lineNo}`)
    );
    setState({
      feedbacks: filteredArray,
    });
  }
}
