import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import type { Facility } from '@tag/graphql';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { GetFacilities } from '@stores-actions/facility.action';
import { FacilityStoreService } from '@stores-services/facility-store.service';

export class FacilityStateModel {
  facilities: Facility[] = [];
  selectedFacility!: Facility | null;
  initialized = false;
}

/**
 * Facility Lines metadata and action mappings.
 */
@State<FacilityStateModel>({
  name: 'facility',
  defaults: {
    facilities: [],
    selectedFacility: null,
    initialized: false,
  },
})
@Injectable()
export class FacilityState {
  constructor(private facilityStoreService: FacilityStoreService) {}

  @Selector()
  static getFacilities(state: FacilityStateModel): Facility[] {
    return state.facilities;
  }

  @Action(GetFacilities, { cancelUncompleted: true })
  getFacilities(
    { getState, setState }: StateContext<FacilityStateModel>,
    { filter, bustCache }: GetFacilities
  ): Observable<Facility[]> {
    let state = getState();
    if (state.initialized && !bustCache) return of(state.facilities);
    return this.facilityStoreService.fetchFacilities(filter).pipe(
      tap((result) => {
        state = getState();
        setState({
          ...state,
          facilities: result,
          initialized: true,
        });
      })
    );
  }
}
