import type { FinishedWorkOrder } from '@tag/graphql';
import { Operation } from 'fast-json-patch';

/**
 * Add Finished Work Order
 *
 * @param payload Finished Work Order object
 */
export class AddFinishedWorkOrder {
  static readonly type = '[FinishedWorkOrder] Add';

  constructor(public payload: FinishedWorkOrder) {}
}

/**
 * Get one specific finished work order
 *
 * @param no finished work order no
 * @param bustCache Bypass current cached stated to get new items from the API.
 */
export class GetFinishedWorkOrder {
  static readonly type = '[FinishedWorkOrder] Get One';

  constructor(public no: string, public bustCache?: boolean) {}
}

/**
 * Get all Finished Work Orders
 *
 * @param filter OData filter
 * @param bustCache Bypass current cached stated to get new items from the API.
 */
export class GetFinishedWorkOrders {
  static readonly type = '[FinishedWorkOrder] Get';

  constructor(public filter?: string, public bustCache?: boolean) {}
}

/**
 * Get summary finished work order
 *
 * @param filter OData filter
 * @param bustCache Bypass current cached stated to get new items from the API.
 */
export class GetSummaryFinishedWorkOrders {
  static readonly type = '[FinishedWorkOrder] Get Summary';

  constructor(public filter?: string, public bustCache?: boolean) {}
}

/**
 * Update Finished Work Order Line, this method will dynamically update the cache
 * Finished Work Order list and selected Finished Work Order.
 *
 * @param no Finished Work Order No
 * @param patch JSON patch object used to update the Finished Work Order. Import Operation[] from fast-json-patch
 * @param selectedItem Force the selected item to change after the update. By default, it will take the updated object.
 */
export class UpdateFinishedWorkOrder {
  static readonly type = '[FinishedWorkOrder] Update';

  constructor(
    public no: string,
    public patch: Operation[],
    public selectedItem?: FinishedWorkOrder
  ) {}
}

/**
 * Delete Finished Work Order, this method will dynamically update the cache Finished Work Order list and set selected
 * Finished Work Order to null.
 *
 * @param no Finished Work Order No
 */
export class DeleteFinishedWorkOrder {
  static readonly type = '[FinishedWorkOrder] Delete';

  constructor(public no: string) {}
}

/**
 * Update local finish work order store
 *
 * @param payload Finished Work Order objects
 */
export class UpdateLocalFinishWorkOrderStore {
  static readonly type = '[FinishedWorkOrder] Update Local';

  constructor(public fwos: FinishedWorkOrder[]) {}
}

/**
 * Set selected Finished Work Order Line
 *
 * @param payload New Finished Work Order Line full object. Summary is not supported.
 */
export class SetSelectedFinishedWorkOrder {
  static readonly type = '[FinishedWorkOrder] Set';

  constructor(public payload: FinishedWorkOrder | null) {}
}
