/**
 * Get all Areas
 *
 * @param filter OData filter
 */
export class GetAreas {
  static readonly type = '[Area] Get';

  constructor(public filter?: string, public bustCache?: boolean) {}
}
